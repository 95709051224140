import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

// components
import { 
  Typography,
  AppBar, Toolbar, 
  LinearProgress, Popover,
  IconButton
} from '@material-ui/core';

// icons
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';

import ReactPlayer from 'react-player'
import dayjs from 'dayjs';
import dayjs_utc from 'dayjs/plugin/utc'
dayjs.extend(dayjs_utc)

const styles = (theme) => ({
  playBar: {
    zIndex: theme.zIndex.drawer + 1,
    bottom: 0,
    top: 'auto',
    backgroundColor: "#272c34"
  },
  fullWidth: {
    width: "100%",
  },
  progressRoot: {
    height: 16,
    cursor: 'pointer'
  },
  progressBuffer: {
    //backgroundSize:"10px 12px"
    backgroundColor: theme.palette.type === 'light' ? lighten(theme.palette.primary.main, 0.62) : darken(theme.palette.primary.main, 0.5),
    opacity:0.5,
    animation: 'none'
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
});

class PlayBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      record: props.record,
      playing: false,
      played: 0, playedSeconds: 0,
      loaded: 0, loadedSeconds: 0,
      totalSeconds: 0,
      popover: {
        visible: false,
        x:0,
        y:0
      }
    }

    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)
    this.seek = this.seek.bind(this)
    this.openOrUpdatePopover = this.openOrUpdatePopover.bind(this)
    this.closePopover = this.closePopover.bind(this)
    this.initPlayer = this.initPlayer.bind(this)
    this.updateProgress = this.updateProgress.bind(this)
    this.onPlayerReady = this.onPlayerReady.bind(this)
    this.onPlayerEnded = this.onPlayerEnded.bind(this)
    this.onError = this.onError.bind(this)

    var self = this;

    this.api = {
      seek: function(seek, play) {
        self.seek(null, seek)
        if (play) {
          self.play()
        }
      }
    }

    if (props.onRef) {
      props.onRef(this.api)
    }
  }

  play(e, data) {
    this.setState({playing: true})
    this.props.dataProvider.checkin({
      operation: 'play',
      playing: true,
      recordingId: this.props.record.id,
      time: this.state.playedSeconds
    })
  }

  pause(e) {
    this.setState({playing: false})
    this.props.dataProvider.checkin({
      operation: 'pause',
      playing: true,
      recordingId: this.props.record.id,
      time: this.state.playedSeconds
    })
  }

  seek(e, where) {
    let position = e? (e.clientX / e.currentTarget.offsetWidth) : where;
    this.player.seekTo(position)
    this.props.dataProvider.checkin({
      operation: 'seek',
      playing: this.state.playing,
      recordingId: this.props.record.id,
      time: e? (position * this.state.totalSeconds): where
    })
  }

  initPlayer(player) {
    this.player = player;
  }

  openOrUpdatePopover(e) {
    let relative = e.clientX / e.currentTarget.offsetWidth
    let x = e.clientX
    let y = e.currentTarget.getBoundingClientRect().top - 8
    this.setState((state) => { 
      return {
        popover: {
          visible: true,
          x: x,
          y: y,
          text: this.formatTime(state.totalSeconds * relative)
        }
      }
    })
  }

  closePopover(e) {
    this.setState({ popover: {visible:false,x:0,y:0}})
  }

  updateProgress(data) {
    this.setState(data)
  }

  onPlayerReady() {
    if (this.player.getDuration) 
      this.setState({totalSeconds: this.player.getDuration()})
    else
      setTimeout(this.onPlayerReady, 500)
  }

  onPlayerEnded() {
    this.setState({playing: false})
  }

  onError(e) {
  }

  formatTime(seconds) {
    const format = seconds>=3600 ? 'h:mm:ss' : 'm:ss'
    return dayjs(seconds*1000).utc().format(format)
  }

  async componentDidMount() {
    this.checkinTimer = setInterval(() => {
      this.props.dataProvider.checkin({
        operation: 'checkin',
        playing: this.state.playing,
        recordingId: this.props.record.id,
        time: this.state.playedSeconds
      })
    }, 10000)
  }

  async componentWillUnmount() {
    clearInterval(this.checkinTimer)
  }

  render() {
    const { classes } = this.props
    return (
      <AppBar position="fixed" className={classes.playBar} ref={this.initPlayer}>
        <ReactPlayer
          ref={(player) => this.initPlayer(player)}
          url={this.props.dataProvider.getStreamURL(this.props.record)} 
          playing={this.state.playing}
          onProgress={this.updateProgress}
          onReady={this.onPlayerReady}
          onEnded={this.onPlayerEnded}
          onError={this.onError}
          progressInterval={200}
          height={0}
        />
        <div className={classes.fullWidth}>
          <LinearProgress 
            variant="buffer" 
            value={this.state.played*100} valueBuffer={this.state.loaded*100} 
            classes={{root: classes.progressRoot, dashedColorPrimary: classes.progressBuffer}} 
            onMouseMove={this.openOrUpdatePopover} 
            onMouseEnter={this.openOrUpdatePopover}
            onMouseLeave={this.closePopover}
            onClick={this.seek}
          />
        </div>
        <Toolbar>
          <Typography>{this.formatTime(this.state.playedSeconds)} / {this.formatTime(this.state.totalSeconds)}</Typography>
          { this.state.playing 
            ? <IconButton onClick={this.pause} color="primary"><PauseIcon fontSize="large" /></IconButton>
            : <IconButton onClick={this.play} color="primary"><PlayIcon fontSize="large" /></IconButton>
          }
          <Typography>{this.props.record.data.name} ({this.props.record.data.stream.name})</Typography>
        </Toolbar>
        <Popover 
          open={this.state.popover.visible} 
          anchorReference="anchorPosition" 
          anchorPosition={{top:this.state.popover.y, left:this.state.popover.x}}
          transformOrigin={{vertical:'bottom', horizontal:'center'}}
          onClose={this.closePopover} 
          disableRestoreFocus className={classes.popover} classes={{paper: classes.popoverPaper}}>
          <Typography>{this.state.popover.text}</Typography>
        </Popover>
      </AppBar>
    )
  }
}

export default withStyles(styles)(PlayBar)