import React from "react";
import { useAuth0 } from "./react-auth0-spa";

const AppProtector = (props) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (loading) {
    return <div>loading...</div>
  } else if (!isAuthenticated) {
    loginWithRedirect({})
    return <div></div>
  } else {
    return props.children
  }
};

export default AppProtector;