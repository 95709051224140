import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat  from 'dayjs/plugin/localizedFormat'

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// components
import { 
  Paper, 
  Typography,
  Table, TableHead, TableContainer, TableCell, TableRow, TableBody,
  Fab, Tooltip, IconButton, Button,
  Menu, MenuItem,
  Dialog, DialogActions, /*DialogContent, DialogContentText,*/ DialogTitle
} from '@material-ui/core';
import ScheduleDialog from './ScheduleDialog.js'


import AddIcon from '@material-ui/icons/Add';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import MoreIcon from '@material-ui/icons/MoreVert';
import RecordIcon from '@material-ui/icons/FiberManualRecord';
import TimerIcon from '@material-ui/icons/AvTimer';

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
const styles = (theme) => ({
  addButton: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(4),
  }
});

class RowRecorded extends React.Component {
  render() {
    const sizeFormat = Intl.NumberFormat('en-US', {style:'unit', unit:'megabyte', unitDisplay:'short', maximumFractionDigits:1, minimumFractionDigits:1})
    const rowData = this.props.data.data
    console.log(rowData)
    const time = (rowData.status === "DONE") ? rowData.actual : rowData.schedule
    const data = {
      name: ( rowData.name || 'Unnamed' ),
      time: `${dayjs(time.start).format('ddd, MMM D LT')} - ${dayjs(time.end).format('LT')}`,
      source: rowData.stream.name || rowData.stream.url,
      size: (rowData.meta && rowData.meta.size)? sizeFormat.format(rowData.meta.size/(1024*1024)) : '-',
      length: (rowData.status === 'DONE') ? dayjs(rowData.actual.end).from(rowData.actual.start, true) : ''
    }


    var icon;
    if (rowData.status==='DONE') {
      icon = <IconButton aria-label="play" onClick={this.props.onPlay}>
        <PlayIcon fontSize="large" color="primary" />
      </IconButton>
    } else if (rowData.status==='RECORDING') {
      icon = 
        <RecordIcon style={{marginLeft:18, color:'red'}} />
    } else if (rowData.status==='SCHEDULED') {
      icon = 
        <TimerIcon style={{marginLeft:18, color:'#aaa'}} />
    }

    return (
      <TableRow key={this.props.data.id}>
        <TableCell padding="checkbox">
          { icon }
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography color="textPrimary" variant="h6">{data.name}</Typography>
          <Typography color="textSecondary" variant="body2">({data.time})</Typography>
        </TableCell>
        <TableCell>{data.source}</TableCell>
        <TableCell>{data.length}</TableCell>
        {/* <TableCell>{data.size}</TableCell> */}
        <TableCell padding="none">
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>this.props.onMenuOpen(e, data)}>
            <MoreIcon fontSize="large" />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

class TableRecorded extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuAnchor: null,
      deleteConfirmationOpen: false
    }
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.handleDeleteConfirmationClose = this.handleDeleteConfirmationClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  openMenu(e, data) {
    this.setState({menuAnchor: e.currentTarget, menuData: data})
  }

  closeMenu(e, data) {
    this.setState({menuAnchor: null})
  }

  handleDeleteConfirmationClose() {
    this.setState({deleteConfirmationOpen: false})
  }

  handleDelete() {
    this.props.onDelete(this.state.menuData)
    this.handleDeleteConfirmationClose()
  }

  render() {
    const recording = this.state.menuData;
    const recordingName = (recording && recording.data)? recording.data.name : "";

    return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Recording</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Length</TableCell>
                {/* <TableCell>Size</TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.rows.map((row) => 
                <RowRecorded 
                  data={row} 
                  onMenuOpen={(e) => this.openMenu(e, row)} 
                  onPlay={(e) => this.props.onPlay(e, row)} 
                  key={row.id}
                />)
              }
            </TableBody>
          </Table>
        </TableContainer>            
        <Menu
          id="simple-menu"
          anchorEl={this.state.menuAnchor}
          keepMounted
          open={Boolean(this.state.menuAnchor)}
          onClose={this.closeMenu} >
          <MenuItem onClick={() => {this.setState({deleteConfirmationOpen: true}); this.closeMenu()}}>Delete</MenuItem>
        </Menu>
        <Dialog
          open={this.state.deleteConfirmationOpen}
          onClose={this.handleDeleteConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete {recordingName}?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleDelete} color="secondary">
              Delete
            </Button>
            <Button onClick={this.handleDeleteConfirmationClose} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

class RecordingsContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data || [],
      scheduleDialogOpen: false
    }
    this.schedule = this.schedule.bind(this)
    this.delete = this.delete.bind(this)
  }

  reload() {
    this.props.dataProvider.loadRecordings().then((data) => {
      this.setState({data:data})
    })
  }

  schedule(data) {
    this.props.dataProvider.schedule(data)
    this.reload()
  }

  delete(data) {
    this.props.dataProvider.delete(data)
    this.reload()
  }

  componentDidMount() {
    this.reload()
    this.checkInterval = setInterval(() => {
      var shouldChange = this.state.data.filter((r) => 
        (r.data.status === 'RECORDING' && dayjs().isAfter(dayjs(r.data.schedule.end)))
        ||
        (r.data.status === 'SCHEDULED' && dayjs().isAfter(dayjs(r.data.schedule.start)))
      );
      if (shouldChange.length > 0) {
        console.log('Expecting some recordings to change status, reloading', shouldChange)
        this.reload()
      }
    }, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.checkInterval)
  }

  render() {
    const { classes } = this.props
    const statusOrdering = ['DONE', 'RECORDING', 'SCHEDULED']

    const data = this.state.data
      .filter(r => statusOrdering.indexOf(r.data.status) > -1)
      .sort((a,b) => (statusOrdering.indexOf(a.data.status) - statusOrdering.indexOf(b.data.status)) || dayjs(a.data.schedule.start).diff(b.data.schedule.start))

    return (
      <React.Fragment>
        <TableRecorded rows={data} onPlay={this.props.onPlay} onDelete={this.delete}/>
        <Tooltip title="Add" aria-label="add">
          <Fab color="primary" className={classes.addButton} onClick={(e) => {this.setState({scheduleDialogOpen: true})}}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <ScheduleDialog open={this.state.scheduleDialogOpen} 
          onClose={()=>this.setState({scheduleDialogOpen: false})} 
          onSchedule={(data)=>this.schedule(data)} />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(RecordingsContent)