import config from 'react-global-configuration';
import createAuth0Client from "@auth0/auth0-spa-js";
import authConfig from "./auth_config.json";

class DataProvider {

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getToken() {
    if (!this.flag) {
      this.flag=true;
      try {
        this.auth0 = await createAuth0Client({
          domain: authConfig.domain,
          client_id: '4OKgQA2UmtGzfZDBNuW1APGq6PhmdSYV',
          audience:'rec.tom-kmec.net'
        });
      } catch (e) {
        console.error(e)
      }
      this.flag=false;
    } else if (!this.auth) {
      await this.sleep(1000)
      return await this.getToken()
    }
    return await this.auth0.getTokenSilently();
  }

  async loadRecordings() {
    let token = await this.getToken();
    let response = await fetch(
      config.get('backend') + 'recording', 
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return await response.json()
  }

  async schedule(data) {
    let token = await this.getToken();
    return fetch(config.get('backend') + 'recording', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      body: JSON.stringify(data)
    })
  }

  getStreamURL(record) {
  	return config.get('backend') + record.data.streamURL;
  }

  async delete(record) {
    let token = await this.getToken();
    return fetch(config.get('backend') + 'recording/' + record.id, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    })
  }

  async getState() {
    let token = await this.getToken();
    let response = await fetch(config.get('backend') + 'state', {
      headers: {Authorization: `Bearer ${token}`}
    })
    return await response.json()
  }

  async checkin(data) {
    let token = await this.getToken();
    fetch(config.get('backend') + 'checkin', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      body: JSON.stringify(data)
    })
  }
}

export default DataProvider