import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// components
import { 
  Typography,
} from '@material-ui/core';


const styles = (theme) => ({
});

class StationsContent extends React.Component {

  render() {
    // const { classes } = this.props
    return (
      <Typography>Stations</Typography>
    )
  }
}

export default withStyles(styles)(StationsContent)