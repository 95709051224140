import React, {Fragment} from "react";
import { 
  Typography, Avatar, Button
} from '@material-ui/core';

import { useAuth0 } from "./react-auth0-spa";

const UserInfo = () => {
  const { user, logout } = useAuth0();
  const logoutAndRedirect = () => {
    let l = window.location
    let here = l.href.substring(0, l.href.lastIndexOf(l.pathname))
    console.log(here)
    logout({returnTo:here})
  }
  return (
    <Fragment>
      <div style={{flexGrow: 1}} />
      <Typography>{user.name}</Typography>
      <Avatar style={{margin:"0 1em"}} alt={user.name} src={user.picture}/>           
      <Button color="secondary" variant="outlined" onClick={logoutAndRedirect}>Logout</Button>
    </Fragment>
  );
};

export default UserInfo;

