import React from 'react';
import ReactDOM from 'react-dom';
import config from 'react-global-configuration';

import { navigate } from "@reach/router";

import { Auth0Provider } from "./react-auth0-spa";
import authConfig from "./auth_config.json";

import AppProtector from './AppProtector';
import RecorderApp from './RecorderApp';

config.set({ backend: process.env.REACT_APP_REC_BACKEND || 'http://localhost:5000/' });

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider domain={authConfig.domain} client_id={authConfig.clientId} redirect_uri={window.location.origin} onRedirectCallback={() => navigate("/")} >
      <AppProtector>
        <RecorderApp />
      </AppProtector>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);