import dayjs from 'dayjs';
import localizedFormat  from 'dayjs/plugin/localizedFormat'

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// components
import { 
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, TextField, Button, Select, MenuItem, InputLabel
} from '@material-ui/core';

dayjs.extend(localizedFormat)
const styles = (theme) => ({
  spacedControls: {
    paddingTop: theme.spacing(2)
  }
});

const stations = [
  { url: 'https://icecast8.play.cz/expres128mp3', name: 'Express FM' },
  { url: 'https://ice7.radia.cz/crowave256.mp3', name: 'ČRo Wave' }
]

class ScheduleDialog extends React.Component {
  constructor(props) {
    super(props)
    this.onClose = this.props.onClose || function(){}
    this.onSubmit = () => {
      
      const errors = {};
      (['stream', 'recordingName', 'from', 'to']).forEach((input) => {
        if (!this.state[input]) errors[input]=true
      })
      this.setState({errors: errors})

      if (Object.keys(errors).length === 0) {
        this.props.onSchedule({
          stream: this.state.stream,
          name: this.state.recordingName,
          schedule: {
            start: dayjs(this.state.from).format(),
            end: dayjs(this.state.to).format()
          }
        })
        this.onClose()
      }
    }

    this.state = {
      errors: {},
      stationIndex:"",
      recordingName:"",
      from:"",
      to:""
    }
  }

  render() {
    const { classes, open } = this.props
    return (
      <Dialog open={open} onClose={this.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Schedule a recording</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} error={this.state.errors.stream}>
            <InputLabel id="station-select-label">Station</InputLabel>
            <Select
              id="station-select" 
              data-testid="station-select"
              labelId="station-select-label"
              value={this.state.stationIndex} 
              onChange={(e)=>{this.setState({stationIndex:e.target.value, stream:stations[e.target.value]})}}>
                {stations.map((v, i) => { 
                  return (<MenuItem key={i} value={i}>{v.name}</MenuItem>)
                })}
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className={classes.spacedControls}>
            <TextField
              id="name"
              label="Recording Name"
              value={this.state.recordingName}
              onChange={(e)=>{this.setState({recordingName:e.target.value})}}
              error={this.state.errors.recordingName}
            />
          </FormControl>
          <FormControl fullWidth={true} className={classes.spacedControls}>
            <TextField id="from" label="Start time" type="datetime-local" 
              value={this.state.from} 
              onChange={(e)=>{this.setState({from:e.target.value})}} 
              error={this.state.errors.from}
              InputLabelProps={{shrink: true}} />
          </FormControl>
          <FormControl fullWidth={true} className={classes.spacedControls}>
            <TextField id="to" label="End time" type="datetime-local" 
              value={this.state.to} 
              onChange={(e)=>{this.setState({to:e.target.value})}}
              error={this.state.errors.to}
              InputLabelProps={{shrink: true}} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose}>
            Cancel
          </Button>
          <Button onClick={this.onSubmit} color="primary" variant="contained" data-testid="schedule">
            Schedule
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ScheduleDialog)