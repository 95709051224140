import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// components
import { 
  CssBaseline, 
  Container, Typography,
  AppBar, Toolbar, 
  //Drawer, List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';

import { Router/*, navigate*/ } from "@reach/router";

//import DashboardContent from './DashboardContent'
import RecordingsContent from './RecordingsContent'
import StationsContent from './StationsContent'
import PlayBar from './PlayBar'

import UserInfo from "./UserInfo"

// icons
//import DashboardIcon from '@material-ui/icons/Dashboard';
import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import StationsIcon from '@material-ui/icons/Radio';

import DataProvider from './DataProvider.js';

const drawerWidth = 0 //240

const styles = (theme) => ({
  drawer: {
    width: drawerWidth
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 1
  },
  content: {
    marginLeft: drawerWidth
  },
});

class RecorderApp extends React.Component {
  constructor(props) {
    super(props)

    this.play = this.play.bind(this)
    this.dataProvider = new DataProvider()

    this.pages = [
      //{ icon: <DashboardIcon />, title: "Dashboard", path: "/", content: <DashboardContent path ="/" key="dashboard" />},
      { icon: <PlaylistIcon />, title: "Recordings", path: "/", content: <RecordingsContent path="/" default onPlay={this.play} dataProvider={this.dataProvider} key="recordings" />},
      { disabled:true, icon: <StationsIcon />, title: "Stations", path: "/stations", content: <StationsContent path="/stations" key="stations" />}
    ]

    this.state = {
      page: props.page || this.pages.map((_) => _.path).indexOf(window.location.pathname) || 0,
    }
  }

  play(e, record, start=true) {
    this.setState({record: record})
    let self = this;
    this.dataProvider.getState().then((state) => {
      self.playbar.seek(state.lastSeek[record.id] || 0, start)
    })
  }

  componentDidMount() {
    this.dataProvider.getState().then((state) => {
      if (state.lastPlayedRecord) {
        this.play(null, state.lastPlayedRecord, false)
      }
    })
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar>
            <Typography variant="h6">Recorder</Typography>
            <UserInfo />
          </Toolbar>
        </AppBar>
        <Toolbar />
{/* no other pages implemented yet, so frreing up space for better mobile use
        <Drawer variant="permanent" className={classes.drawer} classes={{ paper: classes.drawer }}>
          <Toolbar />
          <List>
            {this.pages.map((def, i) => (
              <ListItem button disabled={def.disabled} selected = {i===this.state.page} onClick={() => {this.setState({page: i}); navigate(this.pages[i].path)}} key={def.title}>
                <ListItemIcon>{def.icon}</ListItemIcon>
                <ListItemText primary={def.title} />
              </ListItem>
            ))}
          </List>
        </Drawer>
*/}
        <main className={classes.content}>
          <Container>
            <Router>
              {this.pages.map((def, i) => (
                this.pages[i].content
              ))}
            </Router>
          </Container>
        </main>
        <div style={{height:80}}/>
        {this.state.record && <PlayBar record={this.state.record} dataProvider={this.dataProvider} onRef={(playbar) => this.playbar = playbar}/>}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(RecorderApp)